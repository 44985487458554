import {
  HashRouter as Router,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Countdown from "react-countdown";

import { Alert, Container, Card, Button, Fade } from "react-bootstrap";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://giftapi.pontusw.com"
    : "http://192.168.1.10:8080";

const PreImage = ({ gift, setShow }) =>
  gift.pre_picture ? (
    <Card.Img
      variant="top"
      src={`https://pontus-gift-assets.s3-eu-west-1.amazonaws.com/${gift.pre_picture}.jpg`}
      onLoad={() => setShow(true)}
    />
  ) : (
    ""
  );

// useQuery.get("key")
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/gift/:pubkey">
          <Gift />
        </Route>
      </Switch>
    </Router>
  );
}

function Gift() {
  const params = useParams();
  let query = useQuery();

  const [keys, setKeys] = useState({
    pubkey: params.pubkey,
    privkey: query.get("key"),
  });

  const [show, setShow] = useState(false);

  const [gift, setGift] = useState({});
  const [alerts, setAlerts] = useState([]);

  const unlock = () => {
    setShow(false);
    axios
      .get(`${apiUrl}/unlock/${keys.pubkey}?secret_key=${keys.privkey}`)
      .then((res) => {
        setTimeout(() => {
          setGift(res.data);
        }, 300);
      })
      .catch((error) => {
        setAlerts((oldArray) => [
          ...oldArray,
          error.response ? error.response.data : { detail: "Unknown Error" },
        ]);
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/gift/${keys.pubkey}?secret_key=${keys.privkey}`)
      .then((res) => {
        setGift(res.data);
      })
      .catch((error) => {
        setAlerts((oldArray) => [
          ...oldArray,
          error.response ? error.response.data : { detail: "Unknown Error" },
        ]);
        console.log(error);
      });
  }, [keys.privkey, keys.pubkey]);

  const unlockButton = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Button onClick={unlock} variant="danger">
          Öppna
        </Button>
      );
    } else {
      return (
        <Button variant="danger" disabled>
          Kan öppnas om
          {days && days !== 1 ? ` ${days} dagar ` : ""}
          {days === 1 ? ` ${days} dag ` : ""}
          {hours && hours !== 1 ? ` ${hours} timmar` : ""}
          {hours === 1 ? ` ${hours} timme` : ""}
          {minutes && minutes !== 1 ? ` ${minutes} minuter` : ""}
          {minutes === 1 ? ` ${minutes} minut` : ""}
          {` ${seconds} sekunder`}
        </Button>
      );
    }
  };

  return (
    <Container className="mt-2">
      <div>
        {alerts.map((al, index) => {
          return (
            <Alert key={index} variant="danger">
              {al.detail}
            </Alert>
          );
        })}
        <Fade appear={true} in={show}>
          <Card style={{ maxWidth: "48rem" }}>
            {gift.picture ? (
              <Card.Img
                variant="top"
                src={`https://pontus-gift-assets.s3-eu-west-1.amazonaws.com/${gift.picture}.jpg`}
                onLoad={() => setShow(true)}
              />
            ) : (
              <PreImage gift={gift} setShow={setShow} />
            )}
            {gift.recipient && (
              <Card.Body>
                <Card.Title>
                  {gift.headline} {gift.recipient}
                </Card.Title>
                <Card.Text>
                  {gift.gift_message ? gift.gift_message : gift.pre_message}
                </Card.Text>
                {gift.code && <Card.Text>Kod: {gift.code}</Card.Text>}
                {gift.link && (
                  <Card.Text>
                    <a href={gift.link}>
                      Klicka här och spara länken till ditt presentkort
                    </a>
                  </Card.Text>
                )}
                {!gift.gift_message && (
                  <Countdown date={gift.opening_time} renderer={unlockButton} />
                )}
              </Card.Body>
            )}
          </Card>
        </Fade>
      </div>
    </Container>
  );
}

export default App;
